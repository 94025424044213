import REGION_DATA from './area'
import cloneDeep from 'lodash/cloneDeep'

const regionData = REGION_DATA['86']
let provinceAndCityData = []
let provinceAndCityRegionData = []
let codeToObject = []


// // 计算省
// for (const prop in provinceObject) {
//   regionData.push({
//     value: prop.value, // 省份code值
//     label: prop.text // 省份汉字
//   })
// }
// 计算市
for (let i = 0, len = regionData.length; i < len; i++) {
  const provinceCode = regionData[i].value
  const provinceText = regionData[i].label
  provinceAndCityData.push({
    value: provinceCode,
    label: provinceText,
    children: [],
  })
  const provinceChildren = []
  for (let j = 0, len = regionData[i].children.length; j < len; j++) {
    let val = regionData[i].children[j]
    provinceChildren.push({
      value: val.value,
      label: val.label
    })
  }
  if (provinceChildren.length) {
    provinceAndCityData[i].children = provinceChildren
  }

}
provinceAndCityRegionData = cloneDeep(regionData)

// 计算区
for (let i = 0, len = regionData.length; i < len; i++) {
  const province = regionData[i].children
  const provinceText = regionData[i].label
  codeToObject[regionData[i].value] = {
    province: provinceText,
    city: '',
    region: '',
  }
  if (province) {
    for (let j = 0, len = province.length; j < len; j++) {
      const cityCode = province[j].value
      const cityText = province[j].label
      const cities = province[j].children
      codeToObject[cityCode] = {
        province: provinceText,
        city: cityText,
        region: '',
      }

      for (let k = 0, len = cities.length; k < len; k++) {
        const regionCode = cities[k].value
        const regionText = cities[k].label
        codeToObject[regionCode] = {
          province: provinceText,
          city: cityText,
          region: regionText,
        }
      }


    }
  }
}

export {provinceAndCityRegionData, provinceAndCityData, codeToObject}
